body {
  background-color: black;
}

.App {
  text-align: center;
  background-color: black;
}

.App-logo {
  width: 80%;
  max-width: 500px;
  pointer-events: none;
}

.relative_div {
  position: relative;
}

.abs_text {
  position: absolute;
  animation: text_pulse infinite 2s linear;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: token_motion infinite 2s linear,
    token_pulse infinite 2s linear;
  }
}

.App-header {
  background-color: black;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes token_pulse {
  0%, 100% {
      opacity: 0.2;
      filter: brightness(1) blur(10px);;
  }
  50% {
      opacity: 1;
      filter: brightness(1.3) blur(0px);;
  }
}

@keyframes token_motion {
  0%, 100% {
      transform: translateX(0) translateY(0);
  }
  50% {
      transform: translateX(1.5%) translateY(-3%) rotate(-2deg);
  }
}

@keyframes text_pulse {
  0%, 100% {
    opacity: 1;
  }
  50% {
      opacity: 0
  }
}